import React, {Component} from 'react';
import axios from "axios";
import DashBoardModel from "./Models";
import {Link} from "react-router-dom";
import {Sparklines, SparklinesBars, SparklinesLine} from 'react-sparklines';

class ProjectCard extends Component {
    state = {
        loaded: false,
        deploys: [],
        board: {},
        stats: DashBoardModel
    };

    valueOf(index) {
        let result = this.state.stats[index];
        if (result) {
            return result;
        }
        return DashBoardModel;
    }

    getStatus() {
        if (this.valueOf('História').count > 0) {
            return "Entrega";
        } else {
            return "operação";
        }
    }

    getLabel() {
        if (this.valueOf('História').count > 0) {
            return "success";
        } else {
            return "warning";
        }
    }

    getTotalDeploys(data) {
        let result = [];
        if (data !== undefined) {
            console.log(data);
            for (let each of data) {
                result.push(each.id);
            }
        }
        return result;
    }

    componentDidMount() {
        const board = this.props.board;
        if (typeof board.sid !== "undefined") {
            axios.put(`/api/bsr/${board.sid}`, {}).then((r) => {
                const result = r.data;
                this.setState({stats: result, board: board, loaded: true});
            });
            axios.post(`/api/bsr/${board.sid}`, {}).then((r) => {
                const result = this.getTotalDeploys(r.data);
                this.setState({deploys: result});
            });
        }
    }

    render() {
        return (
            <div className="ibox">
                <div className="ibox-title">
                    <div className="ibox-tools">
                        <span className={`label float-right label-${this.getLabel()}`}>{this.getStatus()}</span>
                    </div>
                    <h5>{this.state.board.name}</h5>
                </div>
                <div className="ibox-content">
                    <div>
                        <div className="float-right text-right">
                            <span className="bar_dashboard"> {this.state.deploys.reduce((a, b) => a + b, 0)} E</span>
                            <br/>
                            <div className="stat-percent font-bold text-success">-% <i className="fa fa-bolt"></i>
                            </div>
                        </div>
                        <h4>
                            <div className="minigaph">
                                <Sparklines data={this.state.deploys}>
                                    <SparklinesBars style={{stroke: "white", fill: "#41c3f9", fillOpacity: ".25"}}/>
                                    <SparklinesLine style={{stroke: "#41c3f9", fill: "none"}}/>
                                </Sparklines>
                            </div>
                            <br/>
                            <small className="m-r">
                                <Link to={`/board/${this.state.board.id}`}>
                                    {this.state.deploys.reduce((a, b) => a + b, 0)} Entregas
                                </Link>
                            </small>
                        </h4>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProjectCard;